/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import React from 'react';
import styled from 'styled-components';
import { Nav } from '@catalogo/core-nav';
import AccessbilityIcon from '@catalogo/ui-americanas-desktop-svg/icon-handtalk.svg';
import { SocialLinks } from './social-links';

export const Social = ({ accessibilityToken }) => {
  /* istanbul ignore next */
  const loadHandTalkScript = () => {
    if (document.getElementById('handTalk')) {
      return;
    }
    const script = document.createElement('script');
    script.id = 'handTalk';
    script.src = '//api.handtalk.me/plugin/latest/handtalk.min.js';
    document.getElementsByTagName('footer')[0].appendChild(script);

    /* istanbul ignore next */
    script.onload = () => {
      HandTalk();
    };
  };

  /* istanbul ignore next */
  function HandTalk() {
    const ht = new HT({
      token: accessibilityToken,
    });
  }

  return (
    <Container>
      <AccessbilityLink onClick={loadHandTalkScript}>
        <AccessbilityIcon height="20px" />
        <AccessbilityText>tradutor para Libras</AccessbilityText>
      </AccessbilityLink>
      <KnowMoreLink to="https://www.americanas.com.br/hotsite/acessibilidade">
        acessibilidade digital <br /> na Americanas
      </KnowMoreLink>
      <SocialLinks />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
`;

const AccessbilityLink = styled.button`
  display: flex;
  background: none;
  border: 1px solid #666;
  border-radius: 4px;
  padding: 6px;
  margin: 0 16px;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover,
  &:active {
    background-color: #e1e1e1;
  }
`;

const AccessbilityText = styled.span`
  margin-left: 6px;
  font-size: 14px;
  font-weight: bold;
  color: #666;
`;

const KnowMoreLink = styled(Nav)`
  display: flex;
  align-items: center;
  text-decoration: underline;
  font-size: 14px;
  cursor: pointer;
  transition: color 0.3s;

  &:hover,
  &:active {
    color: ${({ theme }) => theme.primaryColor};
  }
`;
