import React, { useState } from 'react';
import compose from 'lodash/fp/compose';
import styled from 'styled-components';
import Arrow from '@catalogo/ui-americanas-desktop-svg/arrow.svg';
import { getIn } from 'seamless-immutable';
import withFooter from '@catalogo/service-americanas-footer';
import { withConfig } from '@catalogo/core-connect/config';
import { Nav } from '@catalogo/core-nav';
import { Helmet } from 'react-helmet';
import { HighlightedList } from './components/highlighted-list';
import { MoreInfoOptions } from './components/more-info-options';
import { InfoStamps } from './components/info-stamps';
import { Address } from './components/address';
import { Logo } from './components/logo';
import { Social } from './components/social';

export const Footer = ({ highlightList, moreInfoList, config }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const accessibilityToken = getIn(config, ['footer', 'accessibilityToken'], '');
  const infoText = isExpanded ? 'menos informações' : 'mais informações';

  return (
    <Container>
      <Helmet>
        <script defer src="//selo.siteblindado.com/aw.js" />
      </Helmet>
      <FooterLayout>
        <HighlightedList highlightList={highlightList} />
        <MoreInfoListWrapper $isExpanded={isExpanded}>
          <MoreInfoOptions moreInfoList={moreInfoList} />
          <InfoStamps />
        </MoreInfoListWrapper>
      </FooterLayout>
      <Wrapper>
        <Logo />
        <MoreInfoButton onClick={() => setIsExpanded(!isExpanded)}>
          <HiddenLink>{infoText}</HiddenLink>
          <ArrowIcon $isExpanded={isExpanded} />
        </MoreInfoButton>
        <Social accessibilityToken={accessibilityToken} />
      </Wrapper>
      <AddressWrapper>
        <Address />
        <SiteMap to="https://www.americanas.com.br/mapa-do-site" title="mapa do site">
          mapa do site
        </SiteMap>
      </AddressWrapper>
    </Container>
  );
};

export default compose(withConfig, withFooter)(Footer);

const ArrowIcon = styled(Arrow)`
  fill: #666;
  width: 12px;
  height: 12px;
  margin-left: 8px;
  transform: ${({ $isExpanded }) => ($isExpanded ? 'rotate(180deg)' : '')};
`;

const MoreInfoButton = styled.button`
  width: 190px;
  background: #f6f6f6;
  left: 46%;
  margin-left: -85px;
  padding: 20px;
  top: 0;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 0px 0px 8px 8px;
  cursor: pointer;
`;

const AddressWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 30px;
  color: ${({ theme }) => theme.grey.dark};
`;

const Container = styled.footer`
  position: relative;
  background-color: #eaeaea;
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1280px;
  padding: 20px 0;
  margin: 0 auto;
  position: relative;
  background-color: #eaeaea;
`;

const FooterLayout = styled.div`
  background-color: #f6f6f6;
`;

const MoreInfoListWrapper = styled.div`
  max-width: 1280px;
  margin: 0 auto;
  overflow: hidden;
  height: ${({ $isExpanded }) => ($isExpanded ? '810px' : 0)};

  transition: height 1s ease;
`;

const HiddenLink = styled(Nav)`
  font-size: 14px;

  transition: all 0.3s ease;
  &:hover {
    text-decoration: underline;
    color: ${({ theme }) => theme.primaryColor};
  }
`;

const SiteMap = styled(Nav)`
  font-size: 12px;
  cursor: pointer;
  margin: 20px auto;
  color: #666;

  transition: all 0.3s ease;
  &:hover {
    text-decoration: underline;
    color: ${({ theme }) => theme.primaryColor};
  }
`;
